import React from "react";

const TafItem = ({ item, daytime }) => {
  function alternateOrNot(sort) {
    if ("status" in sort) {
      if ("color" in sort.status) {
        if (sort.status.color.blue) {
          return (
            <span
              style={{
                display: "inline-block",
                backgroundColor: "#00008b",
                border: `1.5px solid ${sort.backgroundColor}`,
                fontSize: "x-small",
                borderRadius: 5,
                color: "white",
                fontWeight: "bold",
                paddingRight: 4,
                paddingLeft: 4,
                paddingTop: 1,
                paddingBottom: 1,
                marginLeft: 5,
              }}
            >
              Below alternate
            </span>
          );
        }
        return null;
      }
      return null;
    }
    return null;
  }

  function addExtraFuel(sort) {
    if ("status" in sort) {
      if ("color" in sort.status) {
        if (daytime) {
          if (
            sort.status.color.secondDayYellow &&
            sort.status.color.dayYellow &&
            !sort.status.color.thirdDayYellow &&
            !sort.status.color.thirdDaySecondAirportYellow
          ) {
            return (
              <span
                style={{
                  display: "inline-block",
                  backgroundColor: "#000",
                  border: `1.5px solid ${sort.backgroundColor}`,
                  fontSize: "x-small",
                  borderRadius: 5,
                  color: "white",
                  fontWeight: "bold",
                  paddingRight: 4,
                  paddingLeft: 4,
                  paddingTop: 1,
                  paddingBottom: 1,
                  marginLeft: 5,
                }}
              >
                Add 340 LBS
              </span>
            );
          }
          return null;
        } else {
          if (
            sort.status.color.secondDayYellow &&
            !sort.status.color.thirdDayYellow &&
            !sort.status.color.thirdDaySecondAirportYellow
          ) {
            return (
              <span
                style={{
                  display: "inline-block",
                  backgroundColor: "#000",
                  border: `1.5px solid ${sort.backgroundColor}`,
                  fontSize: "x-small",
                  borderRadius: 5,
                  color: "white",
                  fontWeight: "bold",
                  paddingRight: 4,
                  paddingLeft: 4,
                  paddingTop: 1,
                  paddingBottom: 1,
                  marginLeft: 5,
                }}
              >
                Add 340 LBS
              </span>
            );
          }
          return null;
        }
      }
      return null;
    }
    return null;
  }

  function crosswindNotice(sort) {
    if ("status" in sort) {
      if ("tooHighCrosswind" in sort.status) {
        if (sort.status.tooHighCrosswind.tooHighCrosswind) {
          return (
            <span
              style={{
                display: "inline-block",
                backgroundColor: "#000",
                border: `1.5px solid ${sort.backgroundColor}`,
                fontSize: "x-small",
                borderRadius: 5,
                color: "white",
                fontWeight: "bold",
                paddingRight: 4,
                paddingLeft: 4,
                paddingTop: 1,
                paddingBottom: 1,
                marginLeft: 5,
              }}
            >
              Crosswind
            </span>
          );
        }
        return null;
      }
      return null;
    }
    return null;
  }

  // function issueTime(item) {
  //   console.log(item);
  //   if (item?.sort?.content?.[0]?.start) {
  //     const startDate = item?.sort?.content?.[0]?.start;
  //     const formattedDate = startDate
  //       ? startDate.toLocaleString("en-GB", { day: "2-digit", month: "2-digit", hour: "2-digit", minute: "2-digit" })
  //       : "";
  //     return formattedDate;
  //   }
  // }

  if ("sort" in item) {
    const res = [];
    item.sort.content.forEach((sort) => {
      const wxString = sort.wx.join(" ");
      res.push(
        <p
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            marginTop: 0,
            marginBottom: 4,
            marginRight: 4,
            backgroundColor: sort.backgroundColor,
            borderRadius: 5,
            color: sort.backgroundColor === "#cc172a" ? "white" : "#344767",
            fontWeight: "bold",
            fontSize: "0.9rem",
            textAlign: "left",
            paddingRight: 4,
            paddingLeft: 4,
            paddingTop: 2,
            paddingBottom: 2,
            marginLeft: 0,
          }}
        >
          {sort.type ? `${sort.type.join(" ")} ` : null}
          {sort.time} {wxString}
          {alternateOrNot(sort)}
          {addExtraFuel(sort)}
          {crosswindNotice(sort)}
        </p>
      );
    });
    return (
      <div style={styles.main}>
        <p style={styles.title}>
          <b>TAF</b>
        </p>
        <div style={styles.header}>{res}</div>
      </div>
    );
  } else {
    return (
      <>
        {item.data ? (
          <div style={styles.p}>
            <p style={styles.title2}>TAF</p> {item.data === "No data available" ? item.data : item.data.slice(5)}
          </div>
        ) : null}
      </>
    );
  }
};

const styles = {
  main: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexWrap: "wrap",
  },
  div: {
    display: "flex",
    flexDirection: "column",
    marginBottom: 10,
    marginTop: 10,
    paddingBottom: 5,
    borderBottom: "solid 1px white",
  },
  header: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexWrap: "wrap",
  },
  h2: {
    margin: 0,
    marginRight: 10,
    textAlign: "left",
  },
  p: {
    margin: 0,
    marginBottom: 4,
    textAlign: "left",
    fontSize: "0.9rem",
  },
  type: {
    margin: 0,
    marginLeft: 10,
    padding: "2px 4px",
    border: "2px solid white",
    borderRadius: 10,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "x-small",
    fontWeight: "bold",
  },
  title: {
    fontWeight: "bold",
    fontSize: "0.5rem",
    margin: 0,
    marginBottom: 3,
    padding: 0,
  },
  title2: {
    fontWeight: "bold",
    fontSize: "0.5rem",
    margin: 0,
    marginBottom: 0,
    padding: 0,
  },
};

export default TafItem;
