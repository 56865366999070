import presetAd from "../json/presetAd.json";

export function getPresetAd(airport) {
  for (let i = 0; i < presetAd.length; i++) {
    const item = presetAd[i];
    if (airport === item.name) {
      return item;
    }
  }
}

export async function fetchMetar() {
  const response = await fetch("https://parseapi.back4app.com/functions/getMetarExtended", {
    method: "POST",
    headers: {
      "X-Parse-Application-Id": process.env.REACT_APP_PARSE_APP_ID,
      "X-Parse-REST-API-Key": process.env.REACT_APP_PARSE_API_KEY,
    },
  });
  const data = await response.json();
  return data;
}

export async function processMetar(data) {
  const processedData = processMetarAndTaf(data);
  return processedData;
}

export async function fetchTaf() {
  const response = await fetch("https://parseapi.back4app.com/functions/getTafExtended", {
    method: "POST",
    headers: {
      "X-Parse-Application-Id": process.env.REACT_APP_PARSE_APP_ID,
      "X-Parse-REST-API-Key": process.env.REACT_APP_PARSE_API_KEY,
    },
  });
  const data = await response.json();
  const processedData = processMetarAndTaf(data);
  return processedData;
}

export async function fetchNotam() {
  const response = await fetch("https://parseapi.back4app.com/functions/getFaaNotam", {
    method: "POST",
    headers: {
      "X-Parse-Application-Id": process.env.REACT_APP_PARSE_APP_ID,
      "X-Parse-REST-API-Key": process.env.REACT_APP_PARSE_API_KEY,
    },
  });
  const data = await response.json();
  return data.result;
}

export async function fetchSigchart() {
  const response = await fetch("https://parseapi.back4app.com/functions/fetchSigchart", {
    method: "POST",
    headers: {
      "X-Parse-Application-Id": process.env.REACT_APP_PARSE_APP_ID,
      "X-Parse-REST-API-Key": process.env.REACT_APP_PARSE_API_KEY,
    },
  });
  const data = await response.json();
  return data.result;
}

export async function fetchTwoSigcharts() {
  const response = await fetch("https://parseapi.back4app.com/functions/fetchTwoSigcharts", {
    method: "POST",
    headers: {
      "X-Parse-Application-Id": process.env.REACT_APP_PARSE_APP_ID,
      "X-Parse-REST-API-Key": process.env.REACT_APP_PARSE_API_KEY,
    },
  });
  const data = await response.json();
  return data.result;
}

export async function fetchWave(airport) {
  let val = `fetchSaveReplyLatestWave`;
  switch (airport) {
    case "Hammerfest":
      val = `fetchSaveReplyLatestWaveNorth`;
      break;
    default:
      val = `fetchSaveReplyLatestWave`;
  }

  const response = await fetch(`https://parseapi.back4app.com/functions/${val}`, {
    method: "POST",
    headers: {
      "X-Parse-Application-Id": process.env.REACT_APP_PARSE_APP_ID,
      "X-Parse-REST-API-Key": process.env.REACT_APP_PARSE_API_KEY,
    },
  });
  const data = await response.json();
  return data.result;
}

export async function fetchHti(airport) {
  let val = `fetchSaveReplyLatestTriggered`;
  switch (airport) {
    case "Hammerfest":
      val = `fetchSaveReplyLatestTriggeredNorth`;
      break;
    default:
      val = `fetchSaveReplyLatestTriggered`;
  }

  const response = await fetch(`https://parseapi.back4app.com/functions/${val}`, {
    method: "POST",
    headers: {
      "X-Parse-Application-Id": process.env.REACT_APP_PARSE_APP_ID,
      "X-Parse-REST-API-Key": process.env.REACT_APP_PARSE_API_KEY,
    },
  });
  const data = await response.json();
  return data.result;
}

export async function fetchGaf(airport) {
  let val = `fetchGaf_ENGC_ENBR_5`;
  switch (airport) {
    case "Bergen":
      val = `fetchGaf_ENGC_ENBR_5`;
      break;
    case "Florø":
      val = `fetchGaf_ENGC_ENFL_5`;
      break;
    case "Sola":
      val = `fetchGaf_ENLE_ENZV_5`;
      break;
    case "Ekofisk":
      val = `fetchGaf_ENLE_ENZV_5`;
      break;
    case "Hammerfest":
      val = `fetchGaf_ENBJ_ENHF_5`;
      break;
    case "Sverdrup":
      val = `fetchGaf_ENJS_ENZV_5`;
      break;
    default:
      val = `fetchGaf_ENGC_ENBR_5`;
  }

  const response = await fetch(`https://parseapi.back4app.com/functions/${val}`, {
    method: "POST",
    headers: {
      "X-Parse-Application-Id": process.env.REACT_APP_PARSE_APP_ID,
      "X-Parse-REST-API-Key": process.env.REACT_APP_PARSE_API_KEY,
    },
  });
  const data = await response.json();
  return data.result;
}

const checkForAd = (adArrayIn, content) => {
  const adArray = [];
  const adNotExisting = [];
  adArrayIn.forEach((ad) => {
    if (content.search(ad) === -1) {
      adNotExisting.push(ad);
    } else {
      adArray.push(ad);
    }
  });
  return { adArray, adNotExisting };
};

const fileToObjArrayExtended = (adInFile, adNotInFile, content) => {
  const objList = [];
  let adTempData = "";
  let textPos = "";
  let id = 0;
  adInFile.forEach((ad) => {
    textPos = content.lastIndexOf(ad);
    const lala = content.slice(0, textPos);
    const lele = lala.lastIndexOf("=");
    adTempData = content.slice(lele + 2, content.indexOf("=", textPos));
    let type = null;
    if (adTempData.lastIndexOf(ad) > 0) {
      type = adTempData.slice(0, adTempData.lastIndexOf(ad)).trim();
      adTempData = adTempData.slice(adTempData.lastIndexOf(ad));
    }
    objList.push({
      id,
      ad: content.slice(textPos, textPos + 4),
      type,
      data: adTempData,
    });
    id += 1;
  });
  adNotInFile.forEach((ad) => {
    objList.push({
      id,
      ad,
      data: "No data available",
    });
    id += 1;
  });
  return objList;
};

const processMetarAndTaf = (data) => {
  const { content, airports } = data.result;
  const fetchTime = data.result.time.iso;
  const { adArray, adNotExisting } = checkForAd(airports, content);
  const objArray = fileToObjArrayExtended(adArray, adNotExisting, content);
  const time = {
    year: fetchTime.slice(0, 4),
    month: fetchTime.slice(5, 7),
    date: fetchTime.slice(8, 10),
    hour: fetchTime.slice(11, 13),
    minute: fetchTime.slice(14, 16),
  };
  const objArrayWithTime = objArray.map((item) => ({ ...item, time }));
  return {
    time,
    objArrayWithTime,
  };
};
