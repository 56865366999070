import React from "react";
import "../App.css";
import posthog from "posthog-js";
import CookieBanner from "./CookieBanner";

const Footer = () => {
  return (
    <div style={styles.main}>
      {posthog.has_opted_out_capturing() || // new
      posthog.has_opted_in_capturing() ? null : (
        <CookieBanner />
      )}
      <div style={styles.div}>
        <p>© 2025 Ehatlem AS</p>
        <div>
          <a className="Link" href="mailto:contact@heliplanner.no?subject='Feedback web 2.6.0'">
            Email a feedback
          </a>
          <a className="Link" href="https://www.ehatlem.com" target="_blank">
            Ehatlem AS
          </a>
          <a className="Link" href="https://app.websitepolicies.com/policies/view/nrdSFjd9" target="_blank">
            Disclaimer
          </a>
        </div>
      </div>
    </div>
  );
};

const styles = {
  main: {
    // width: "100%",
    position: "-webkit - sticky",
    position: "sticky",
    bottom: 0,
    backgroundColor: "#000",
    marginTop: "auto",
    marginRight: 10,
    marginLeft: 10,
  },
  div: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    marginBottom: 10,
  },
  a: {
    color: "white",
    textDecoration: "none",
    marginLeft: 20,
  },
};

export default Footer;
